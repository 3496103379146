<template>
  <div style="height: inherit">

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items d-block d-sm-flex">
            <b-input-group class="input-group-merge mr-1">
              <b-form-input
                v-model="filters.q"
                placeholder="Поиск"
                class="search-product mb-1 mb-sm-0 "
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>
            <div class="view-options d-flex">
              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="product in products"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <b-link :to="{ name: 'shop-details', params: { id: product.id } }">
            <b-img
              :alt="`${product.name}-${product.id}`"
              class="card-img-top"
              :src="server + product.image"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div class="item-rating">
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="16"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>
            <div v-if="product.prizn == '1'">
              <h6 class="item-price">
                {{ product.price }} руб.
              </h6>
              <h6 class="text-secondary">
                <s>{{ product.priceOld }} руб.</s>
              </h6>
              <h6 class="item-price">
                Цена бал. {{ product.priceBall }}
              </h6>
            </div>
            <div v-else-if="product.prizn == '0'">
              <h6 class="item-price">
                {{ product.priceOld }} руб.
              </h6>
              <div class="d-flex align-items-center">
                <h6 class="text-secondary mr-50 mb-0">
                  {{ product.price }} руб.
                </h6>
                <b-button
                  v-b-tooltip.hover.v-primary
                  :title="product.priznComment"
                  variant="flat-primary"
                  class="btn-icon rounded-circle p-0 m-0"
                >
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </div>
              <h6 class="item-price">
                Цена бал. {{ product.priceBall }}
              </h6>
            </div>
            <div v-else>
              <h6 class="item-price">
                {{ product.price }} руб.
              </h6>
              <h6 class="item-price">
                Цена бал. {{ product.priceBall }}
              </h6>
            </div>
          </div>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{ name: 'shop-details', params: { id: product.id } }"
            >
              {{ product.name }}
            </b-link>
            <b-card-text class="item-company">
              От <b-link class="ml-25">
                {{ product.brand }}
              </b-link>
            </b-card-text>
          </h6>
          <span :class="`text-${product.availableColor} mb-1`">{{ product.available }}</span>
          <b-card-text class="item-description">
            <div v-html="product.description" />
          </b-card-text>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <h4 class="item-price">
                {{ product.price }} руб.
              </h4>
            </div>
          </div>
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="toggleProductInWishlist(product)"
          >
            <feather-icon
              icon="HeartIcon"
              class="mr-50"
              :class="{'text-danger': product.isInWishlist !== '0'}"
            />
            <span>Избранное</span>
          </b-button>
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            @click="handleCartActionClick(product)"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="mr-50"
            />
            <span>
              {{ product.isInCart !== '0' ? 'Посмотреть в корзине' : 'Добавить в корзину' }}
            </span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-top">
      <shop-left-filter-sidebar
        :filters="filters"
        :slider-min="sliderMin"
        :slider-max="sliderMax"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        class="d-block d-lg-none"
      />
    </portal>
    <portal to="content-renderer-sidebar-detached-left">
      <shop-left-filter-sidebar
        :filters="filters"
        :slider-min="sliderMin"
        :slider-max="sliderMax"
        :filter-options="filterOptions"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        class="d-none d-lg-block"
      />
    </portal>
  </div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BFormRadioGroup, BFormRadio, BRow, BCol,
  BInputGroup, BInputGroupAppend, BFormInput, BCard, BCardBody, BLink,
  BImg, BCardText, BButton, BPagination, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { watch, ref } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ShopLeftFilterSidebar from './ECommerceShopLeftFilterSidebar.vue'
import { useShopFiltersSortingAndPagination, useShopUi, useShopRemoteData } from './useECommerceShop'
import { useEcommerceUi } from '../useEcommerce'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ShopLeftFilterSidebar,
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
  },
  setup(props, { root }) {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalProducts,
    } = useShopUi()

    const {
      products,
      shopMSG,
      fetchProducts,
      fetchCategories,
      fetchPriceRange,
    } = useShopRemoteData()

    fetchCategories().then(response => {
      const { data } = response.data
      filterOptions.value.categories = data
    })

    const sliderMin = ref(0)
    const sliderMax = ref(100000)

    fetchPriceRange().then(response => {
      const { data } = response.data
      filters.value.priceRange = data
      if (sliderMin.value === 0) {
        // eslint-disable-next-line
        sliderMin.value = data[0]
      }
      if (sliderMax.value === 100000) {
        // eslint-disable-next-line
        sliderMax.value = data[1]
      }
    })

    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      let xparam3

      if (filters.value.priceRange) {
        xparam3 = `[${filters.value.priceRange[0]},${filters.value.priceRange[1]}]`
      }
      fetchProducts({
        params: {
          q: filters.value.q,
          sortby: sortBy.value.value,
          zpage: filters.value.page,
          zstart: filters.value.perPage * (filters.value.page - 1),
          zlimit: filters.value.perPage,
          xparam1: filters.value.categories,
          xparam3,
        },
      })
        .then(response => {
          const { data } = response.data
          products.value = data.rows
          totalProducts.value = data.paging[0].total
        })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const server = process.env.VUE_APP_SERVER

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    const toast = text => {
      root.$bvToast.toast(text, {
        title: 'Сообщение',
        toaster: 'b-toaster-bottom-full',
        solid: true,
        appendToast: true,
        variant: 'primary',
        autoHideDelay: 15000,
      })
    }

    shopMSG().then(res => {
      const { data } = res
      toast(data)
    })

    return {
      server,
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      mqShallShowLeftSidebar,

      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,
      sliderMin,
      sliderMax,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.grid-view .card-img-top {
  height: 200px;
  object-fit: contain;
}
.list-view .card-img-top {
  width: 200px;
}
</style>
